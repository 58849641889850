<template>
  <div>
    <v-row class="ma-4">
      <v-col cols="6">
        <base-material-card color="couleur1" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Locky Luke</div>
            <div class="subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <v-text-field
              v-model="chercheLogiciel"
              label="Chercher"
              @input="chercher()"
            />
            <v-data-table :headers="headers" :items="listeLogiciels">
              <template v-slot:item.actions="{ item }">
                <v-btn class="primary" small icon @click="afficherInfo(item)"
                  ><v-icon small>mdi-key</v-icon></v-btn
                ></template
              >
            </v-data-table>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </base-material-card>
      </v-col>
      <v-col>
        <v-img
          :src="require(`../assets/lockyLuke/${getImage()}.png`)"
          max-width="250"
          class="elevation-4 mt-8"
        >
        </v-img>
      </v-col>
    </v-row>
    <div>
      <v-dialog v-model="dialog" width="500" v-if="sel">
        <v-card>
          <v-card-title>
            {{ sel.nom }}
          </v-card-title>

          <v-card-text>
            <div class="elevation-3 mb-6 pa-3" v-if="sel.description">
            {{ sel.description }}
            </div>

            <div class="elevation-3 mb-6 pa-3" >
            <a :href="sel.url" target="_blank">{{sel.url}}</a>
            </div>
            
            <v-text-field label="usager" :value="sel.usager" readonly />
            
            <v-text-field
              label="Mot de passe"
              append-icon="mdi-clipboard-text-outline"
              :value="motPasse"
              id="tocopy"
              @click:append="copy"
              readonly
              outlined
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chercheLogiciel: "",
      listeLogiciels: [],
      sel: null,
      dialog: false,
      motPasse: "",
      headers: [
        {
          text: "Logiciel",
          value: "nom",
        },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },

  methods: {
    getImage() {
      return Math.floor(Math.random() * 16)
        .toString()
        .padStart(4, "0");
    },
    copy() {
      let input = document.getElementById("tocopy");
      input.select();
      document.execCommand("copy");
    },

    afficherInfo(item) {
      this.sel = item;

      this.$admin.http
        .get(`api/user/infoLogiciels/${item.id}/motPasse`)
        .then((res) => {
          this.dialog = true;
          this.motPasse = res.data.motPasse;
        });
    },

    chercher() {
      this.$admin.http
        .post("api/user/chercherInfoLogiciels", {
          chaine: this.chercheLogiciel,
        })
        .then((res) => (this.listeLogiciels = res.data));
    },
  },
};
</script>
