// https://www.okami101.io/vuetify-admin/guide/resources.html
export default [
  {
    name: "infoLogiciels",
    icon: "mdi-key-outline",
    label: "nom",
    permissions: ["admin"],
    api: "api/admin/infoLogiciels",
    // routes: ["list"],
  },
];
