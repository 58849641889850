<template>
  <va-edit-layout :title="title">
    <info-logiciels-form :id="id" :item="item"></info-logiciels-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
