import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

// https://www.colorcombos.com/color-schemes/410/ColorCombo410.html

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#BEA6A0",
        secondary: "#56423D",
        accent: "#C9341C",
        couleur1: "#0083CE",
        couleur2: "#00B9FF",
      },
    },
  },
});
