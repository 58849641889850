import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
// import jwtAuthProvider from "./auth/jwt";

import "vuetify-admin/src/loader";

import {
  // jsonServerDataProvider,
    simpleRestDataProvider,
  // fakeAuthProvider,
  jwtAuthProvider,
} from "vuetify-admin/src/providers";
import { en, fr } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
// import axios from "axios";

// import jwtAuthProvider from "./jwt2.js";
import { FetchJson } from "vuetify-admin/src/providers";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Create fetch instance with custom authentication headers
 */
const apiURL = process.env.VUE_APP_API_URL;
console.log('apiURL', apiURL)

const http = new FetchJson(apiURL, {
  headers: () => {
    let headers = new Headers({
      Accept: "application/json",
    });

    let token = localStorage.getItem("jwt_token");

    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "🔐 Locky Luke",
  routes,
  locales: {
    en,
    fr,
  },
  translations: ["en", "fr"],
  dataProvider: simpleRestDataProvider(http),
  authProvider: jwtAuthProvider(http),
  // authProvider: punkProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
  },
});
