<template>
  <va-layout id="apunk">
    <va-app-bar
      slot="app-bar"
      :header-menu="headerMenu"
      :profile-menu="profileMenu"
      dense
      dark
      @toggle="
        $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
      "
    ></va-app-bar>
    <va-sidebar
      slot="sidebar"
      :menu="sidebarMenu"
      v-model="drawer"
      :mini-variant="mini"
    ></va-sidebar>
    <va-breadcrumbs slot="header"></va-breadcrumbs>
    <va-aside slot="aside"> </va-aside>
    <!-- <va-footer slot="footer"> </va-footer> -->

    <div slot="footer"></div>
  </va-layout>
</template>

<style>
#apunk .v-main {
  /* background-color: #772404; */
  /* margin: auto; */
  /* overflow: auto;
  background: linear-gradient(180deg, #a16b23, #771b0c, #000, #c9341c);
  animation: gradient 15s ease infinite;
  background-size: 200% 300%;
  background-attachment: fixed;
  animation: gradient 15s ease infinite; */

  background-image: linear-gradient(
    to right top,
    #bea6a0,
    #a38c86,
    #88726c,
    #6f5954,
    #56423d
  );
}
</style>

<script>
import nav from "../_nav";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
      mini: false,
      headerMenu: [
        {
          link: "/",
          text: this.$t("menu.dashboard"),
        },
      ],
      footerMenu: [
        {
          href: "#",
          text: "About Us",
        },
        {
          href: "#",
          text: "Blog",
        },
        {
          href: "#",
          text: "License",
        },
      ],
      profileMenu: [],
      sidebarMenu: nav(this.$i18n, this.$admin),
    };
  },
  created() {},
  methods: {},
};
</script>
