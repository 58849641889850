<template>
  <v-app>
    <v-container fluid fill-height id="login-page">
      <v-layout align-center justify-center>
        <v-flex :style="{ 'max-width': '550px' }">
          <base-material-card color="couleur2">
            <template v-slot:heading>
              <div class="display-3 text-center funk">
                {{ $admin.title }}
              </div>
            </template>
            <v-card-text>
              <div class="text-center mb-4">
                <v-img
                  :src="require(`../assets/lockyLuke/${getImage()}.png`)"
                  max-height="200"
                  contain
                />
              </div>
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Acme");

div.funk {
  /* color:red; */
  font-family: "Acme", sans-serif !important;
  font-size: 32px !important;
}
#login-page {
  /* background-color: #772404; */
  /* margin: auto;
  overflow: auto;
  background: linear-gradient(315deg, #771b0c, #000, #c9341c, #a16b23);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  animation: gradient 15s ease infinite; */
  background-image: linear-gradient(
    to right top,
    #bea6a0,
    #a38c86,
    #88726c,
    #6f5954,
    #56423d
  );
}
</style>

<script>
export default {
  data() {
    return {};
  },

  created() {
    console.log("ko");
  },
  methods: {
    getImage() {
      return Math.floor(Math.random() * 16)
        .toString()
        .padStart(4, "0");
    },
  },
};
</script>
