import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";
// import axios from "axios";

Vue.config.productionTip = false;

/*
    le fichier env_public.json n'est pas nécessaire mais
    pratique pour la containerisation de l'application
    */

// admin.http.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// const jwt = localStorage.getItem("jwt");
// if (jwt) {
//   admin.http.defaults.headers.common["Authorization"] = jwt;
// }
start();

function start() {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    admin,
    render: (h) => h(App),
  }).$mount("#app");
}
